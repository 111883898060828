import React from 'react'


function Sociall() {
    return (
        <div className='sociall'>
            <img src='/social+/b&e.webp' alt='imgSocial' />
            <img src='/social+/romance.webp' alt='imgSocial' />
            <img src='/social+/social getaway.webp' alt='imgSocial' />
        </div>
    )
}

export default Sociall