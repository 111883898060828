const sliderData = [
    {
        name: "BEACH&SAND GETAWAY",
        urlimg: "/vivencias_imagen/2.webp",
        urlvideo: "/videos/VIVENCIAS/2.mp4"
    },
    {
        name: "SHOPPING HIKE",
        urlimg: "/vivencias_imagen/3.webp",
        urlvideo: "/videos/VIVENCIAS/3.mp4"
    },
    {
        name: "CHEF'S TABLE",
        urlimg: "/vivencias_imagen/4.webp",
        urlvideo: "/videos/VIVENCIAS/4.mp4"
    },
    {
        name: "CANDLELIGHT DINNER",
        urlimg: "/vivencias_imagen/5.webp",
        urlvideo: "/videos/VIVENCIAS/5.mp4"
    },
    {
        name: "TORCHLIGHT FEAST",
        urlimg: "/vivencias_imagen/6.webp",
        urlvideo: "/videos/VIVENCIAS/6.mp4"
    },
    {
        name: "BAREFOOT GALA",
        urlimg: "/vivencias_imagen/7.webp",
        urlvideo: "/videos/VIVENCIAS/7.mp4"
    },
    {
        name: "AQUA ALFRESCO",
        urlimg: "/vivencias_imagen/8.webp",
        urlvideo: "/videos/VIVENCIAS/8.mp4"
    },
    {
        name: "DREAMDATE CINEMA",
        urlimg: "/vivencias_imagen/9.webp",
        urlvideo: "/videos/VIVENCIAS/9.mp4"
    },
    {
        name: "CELESTIAL GETAWAY",
        urlimg: "/vivencias_imagen/10.webp",
        urlvideo: "/videos/VIVENCIAS/10.mp4"
    },
    {
        name: "MINFFULLNES HIKE",
        urlimg: "/vivencias_imagen/11.webp",
        urlvideo: "/videos/VIVENCIAS/11.mp4"
    },
    {
        name: "BIKING BLISS",
        urlimg: "/vivencias_imagen/12.webp",
        urlvideo: "/videos/VIVENCIAS/12.mp4"
    },
    {
        name: "HORSEBACK RIDING",
        urlimg: "/vivencias_imagen/13.webp",
        urlvideo: "/videos/VIVENCIAS/13.mp4"
    },
    {
        name: "POLO PRESTIGE",
        urlimg: "/vivencias_imagen/14.webp",
        urlvideo: "/videos/VIVENCIAS/14.mp4"
    },
    {
        name: "SUNRISE PADDLES",
        urlimg: "/vivencias_imagen/15.webp",
        urlvideo: "/videos/VIVENCIAS/15.mp4"
    },
    {
        name: "WHALE WATCH WONDER",
        urlimg: "/vivencias_imagen/16.webp",
        urlvideo: "/videos/VIVENCIAS/16.mp4"
    },
    {
        name: "UNDERWATER ODYSEEY",
        urlimg: "/vivencias_imagen/17.webp",
        urlvideo: "/videos/VIVENCIAS/17.mp4"
    },
    {
        name: "HEAVENLY HEIGHTS",
        urlimg: "/vivencias_imagen/18.webp",
        urlvideo: "/videos/VIVENCIAS/18.mp4"
    },
    {
        name: "BAÑO FORESTAL",
        urlimg: "/vivencias_imagen/19.webp",
        urlvideo: "/videos/VIVENCIAS/19.mp4"
    },
    {
        name: "BAÑO DE ARENA",
        urlimg: "/vivencias_imagen/20.webp",
        urlvideo: "/videos/VIVENCIAS/20.mp4"
    },
    {
        name: "NAHUATL CLEANSE",
        urlimg: "/vivencias_imagen/21.webp",
        urlvideo: "/videos/VIVENCIAS/21.mp4"
    },
    {
        name: "YOGA UNEARTHED",
        urlimg: "/vivencias_imagen/22.webp",
        urlvideo: "/videos/VIVENCIAS/22.mp4"
    }, {
        name: "NEWFIRE CEREMONY",
        urlimg: "/vivencias_imagen/23.webp",
        urlvideo: "/videos/VIVENCIAS/23.mp4"
    }, {
        name: "SUNBATH PICNIC",
        urlimg: "/vivencias_imagen/24.webp",
        urlvideo: "/videos/VIVENCIAS/24.mp4"
    }, {
        name: "MAYAN CLEANSE",
        urlimg: "/vivencias_imagen/25.webp",
        urlvideo: "/videos/VIVENCIAS/25.mp4"
    }, {
        name: "SUNSET YOGA",
        urlimg: "/vivencias_imagen/26.webp",
        urlvideo: "/videos/VIVENCIAS/26.mp4"
    }, {
        name: "NATURE'S GAZE ",
        urlimg: "/vivencias_imagen/27.webp",
        // urlvideo: "/videos/VIVENCIAS/27.mp4"
    }, {
        name: "CITY TREKKER",
        urlimg: "/vivencias_imagen/28.webp",
        // urlvideo: "/videos/VIVENCIAS/28.mp4"
    }, {
        name: "Bussiness and Executive",
        urlimg: "/social+/b&e.webp",
    },
    {
        name: "Romance",
        urlimg: "/social+/romance.webp",
    },
    {
        name: "Social Getaway",
        urlimg: "/social+/social getaway.webp",
    },


];


export default sliderData