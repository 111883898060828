// ScapeVivencias.js
import React from 'react';
import { VivenciasComponent } from '../Vivencias/Vivencias';

function ScapeVivencias({ sliderCards, sliderData }) {
    return (
        <section className="Vivencias" id="Vivencias">
            <VivenciasComponent slider={sliderData} names={sliderCards[0]} />
        </section>
    );
}

export default ScapeVivencias;
