import React from 'react'
import Btn from '../../Components/Btn'
import ContainService from './Service'
import Video from './Video'
import Sociall from '../Social/Social'
import imagen from '../../Img/1.webp';



function Home() {
    return (
        <div className='Home'>
            <img src={imagen} alt="" />
            <section className='flex-center'>
                <Btn
                    link="https://api.whatsapp.com/send/?phone=5512447233&text=%C2%A1Hola%21+Me+encantar%C3%ADa+que+me+ayudaran+a+agendar+una+traves%C3%ADa+inolvidable&type=phone_number&app_absent=0"
                    target="_blank"
                    text="ContÁctanos"
                />
                <h2 className='title-1'>PASSION LED US HERE</h2>
            </section>

            <ContainService />

            <Video url='/videos/video.mp4' />

            <section className='btn-container flex-center'>
                <Btn
                    link='/Social'
                    clasbtn='social'
                    text='Social+'
                />
                <p className='p-social'>Más información</p>
            </section>

            <Sociall />

            <img className='caballo' src={require('../../Img/caballo.webp')} alt='caballo' />

        </div>
    )
}

export default Home