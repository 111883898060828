const styles = `
    /* Base container */
    .slider-container {
    --slide-spacing: 1rem;
    --button-size: clamp(2.5rem, 4vw, 3rem);
    --overlay-opacity: 0.3;
    --transition-speed: 0.5s;
    height: 550px;
    position: relative;
    width: min(100% - 5px, 1200px);
    margin-inline: auto;
    }

    /* Slider wrapper */
    .slider-wrapper {
    position: relative;
    height: clamp(300px, 80vh, 700px);
    border-radius: 0.5rem;
    overflow: hidden;
    touch-action: pan-y pinch-zoom;
    }

    /* Track for slides */
    .slider-track {
    position: absolute;
    inset: 0;
    display: flex;
    transition: transform var(--transition-speed) ease-in-out;
    will-change: transform;
    }

    /* Individual slides */
    .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity var(--transition-speed);
    }

    .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    -webkit-user-drag: none;
    user-select: none;
    }

    @media (min-width: 650px) {
        .imgSocial-no-video {
            height: 500px !important;
        }
    }

    @media (max-width: 650px) {
        .imgSocial-no-video {
            height: 100px !important;
        }
    }

    /* Slide overlay with play button */
    .slide-overlay {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    background-color: rgb(0 0 0 / var(--overlay-opacity));
    opacity: 0;
    transition: opacity 0.3s ease;
    }

    .slide-overlay:hover {
    opacity: 1;
    }

    .slide-overlay svg{
        margin-right: 30px;
    }

    /* Title area */
    .slide-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: clamp(0.75rem, 3vw, 1.25rem);
    background: linear-gradient(
        transparent,
        rgb(0 0 0 / 0.8)
    );
    transform: translateY(0);
    transition: transform 0.3s ease;
    }

    .slide-title h3 {
    color: #fff;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    margin: 0;
    line-height: 1.4;
    }

    /* Navigation buttons */
    .nav-buttons {
    position: absolute;
    bottom: var(--slide-spacing);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 1rem;
    z-index: 10;
    }

    .nav-button {
    width: var(--button-size);
    height: var(--button-size);
    border: none;
    border-radius: 50%;
    background: #fff;
    color: #333;
    cursor: pointer;
    display: grid;
    place-items: center;
    padding: 0;
    box-shadow: 0 2px 8px rgb(0 0 0 / 0.2);
    transition: transform 0.2s, background-color 0.2s;
    }

    .nav-button:hover {
    background: #f0f0f0;
    transform: scale(1.05);
    }

    .nav-button:active {
    transform: scale(0.95);
    }

    /* Modal styles */
    .modal-overlay {
        width: 100%;
        position: fixed;
        inset: 0;
        background: rgb(0 0 0 / 0.85);
        display: grid;
        place-items: center;
        padding: 10px;
        z-index: 1000;
        opacity: 0;
        animation: fadeIn 0.3s ease forwards;
    }

    .modal-content {
    position: relative;
    width: 100%;
    background: #000;
    border-radius: 0.5rem;
    overflow: hidden;
    transform: scale(0.95);
    opacity: 0;
    animation: scaleIn 0.3s ease forwards 0.1s;
    }

    .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    border-radius: 50%;
    background: rgb(0 0 0 / 0.5);
    color: #fff;
    cursor: pointer;
    display: grid;
    place-items: center;
    transition: background-color 0.2s;
    z-index: 10;
    }

    .close-button:hover {
    background: rgb(0 0 0 / 0.7);
    }

    .modal-video {
    width: 700px;
    display: block;
    }

    /* Animations */
    @keyframes fadeIn {
    to {
        opacity: 1;
    }
    }

    @keyframes scaleIn {
    to {
        transform: scale(1);
        opacity: 1;
    }
    }

    /* Estilos específicos para el video por defecto en pantallas grandes */
    @media (min-width: 769px) {
        .default-video-modal {
            width: auto !important;
            height: 80% !important;
        }

        .default-video {
            width: 300px !important;
            height: 90% !important;

        }
    }

    /* Responsive adjustments */
    @media (max-width: 768px) {
        .slide-overlay {
            opacity: 1;
            --overlay-opacity: -0.8;
        }

        .modal-video {
            width: 100%;
        }
        
        .nav-button {
            min-width: 44px;
            min-height: 44px;
        }

        /* Estilos para el video por defecto en pantallas pequeñas */
        .default-video-modal {
            width: 100% !important;
            height: auto !important;
        }

        .default-video {
            width: 100% !important;
            height: 90% !important;
        }
    }

    @media (max-width: 480px) {
        .slider-wrapper {
            height: clamp(500px, 500px, 500px);
        }
        
        .nav-buttons {
            bottom: 0.5rem;
        }
        
        .slide-title {
            width: 100%;
            text-align: center;
            -webkit-border-top-right-radius: 30px;
            -webkit-border-bottom-right-radius: 30px;
            -moz-border-radius-topright: 30px;
            -moz-border-radius-bottomright: 30px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            height: 25px;
            padding: 0;
        }

        .slide-title h3 {
            font-weight: bold;
        }
    }

    /* Prefers reduced motion */
    @media (prefers-reduced-motion: reduce) {
        .slider-track,
        .slide-overlay,
        .slide-title,
        .nav-button,
        .modal-overlay,
        .modal-content {
            transition: none;
            animation: none;
        }
    }

    /* High contrast mode */
    @media (forced-colors: active) {
        .nav-button {
            border: 1px solid currentColor;
        }
        
        .close-button {
            border: 1px solid currentColor;
        }
    }
`;

export default styles