import React from 'react'

function Categorias({ styleCategory, id, titleCategory, imgcategory, textCategory }) {
    return (
        <div className='categorias' style={styleCategory} id={id}>
            <CategoryTitle
                titleCategor={titleCategory}
            />

            <section className='cards'>
                <img src={imgcategory} alt='Kuxtal' />
                <p>{textCategory}</p>
            </section>
        </div>
    )
}

export function CategoryTitle({ titleCategor }) {
    return (
        <section className='categorias-title'>
            <h2>{titleCategor}</h2>
            <hr></hr>
        </section>
    )
}



export default Categorias