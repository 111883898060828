import { useEffect } from 'react';

function Style() {
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://dreamgateways.net/0-S-R-DG-CSS/styles.css';
        document.head.appendChild(link);

        // Limpieza del enlace si el componente se desmonta
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    // No necesita renderizar nada
    return null;
}

export default Style;
