// ScapeLink.js
import React from 'react';
import { Link } from 'react-router-dom';

function ScapeLink({ link, text }) {
    const handleNavigation = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            console.warn(`Sección con ID "${id}" no encontrada.`);
        }
    };

    return (
        <Link
            to="#"
            onClick={(e) => {
                e.preventDefault();
                handleNavigation(link);
            }}
        >
            {text}
        </Link>
    );
}

export default ScapeLink;
