import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CategoryTitle } from '../Trevesias/Categorias';
import CardsPost from './CardsPost';

const regiones = [
    "Estado de México",
    "Jalisco",
    "Puebla",
    "Cancún",
    "Berlín",
    "Reino Unido"
];

const Region = ({ title, option, isLink = false, handleNavigation }) => {
    return (
        <>
            <h1 className="text-2xl font-bold mb-4">{title}</h1>
            <article className="space-y-2">
                {isLink ? (
                    option.map((item, index) => (
                        <p key={index} className="pl-4">
                            &gt;{' '}
                            <span
                                onClick={() => handleNavigation(item)} // Usar handleNavigation
                                className="text-blue-600 hover:text-blue-800 hover:underline cursor-pointer"
                            >
                                {item}
                            </span>
                        </p>
                    ))
                ) : (
                    option.map((location, index) => (
                        <p key={index} className="pl-4">
                            &gt; {location}
                        </p>
                    ))
                )}
            </article>
        </>
    );
};

function DreamScapes() {
    const navigate = useNavigate();

    const handleNavigation = (id) => {
        // Usar navigate para redirigir a la página deseada
        navigate('/travesias');

        // Esperar un pequeño tiempo para que la página cargue y luego hacer scroll
        setTimeout(() => {
            const section = document.getElementById(id); // Buscar el elemento con el id
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Desplazamiento suave
            }
        }, 100); // Tiempo de espera para asegurar que la página haya cargado
    };

    return (
        <div className='dreamscapes flex-center'>
            <section className='title flex-center'>
                <h1>HOTELS & RESIDENCES</h1>
                <h2>travesias increibles</h2>
            </section>

            <section className='post'>
                <CategoryTitle
                    titleCategor='Propiedades'
                />

                <div className='posts'>
                    <CardsPost />
                </div>
            </section>

            <section className='region'>
                <Region title="Region" option={regiones} handleNavigation={handleNavigation} /> {/* Pasar handleNavigation */}

                <h1>Interes</h1>
                <article>
                    {['KUXTAL', 'AMATI', 'ZAZIL'].map((id, index) => (
                        <Link
                            key={index}
                            to="/travesias"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavigation(id); // Llamar a handleNavigation con el id correspondiente
                            }}
                        >
                            {'>'} {id} {/* Usar {'>'} para evitar el error */}
                        </Link>
                    ))}
                </article>

            </section>
        </div>
    );
}

export default DreamScapes;
