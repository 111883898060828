// ScapeMenu.js
import React from 'react';
import ScapeLink from './ScapeLink';
import Btn from '../../Components/Btn';

function ScapeMenu({ scapeName }) {
    return (
        <section className="menuScape">
            <article className="links">
                <ScapeLink link="Info" text="Info" />
                <ScapeLink link="Vivencias" text="Vivencias" />
                <ScapeLink link="Galeria" text="Galeria" />
            </article>
            <article className="btnScape">
                <Btn
                    link={`https://api.whatsapp.com/send/?phone=5512447233&text=${encodeURIComponent(
                        `¡Hola! Me encantaría que me ayudaran a agendar una travesía inolvidable en ${scapeName}`
                    )}&type=phone_number&app_absent=0`}
                    clasbtn="btnReserva"
                    target="_blank"
                    text="Reserva Ahora"
                />
            </article>
        </section>
    );
}

export default ScapeMenu;
