const Scapes = [
    {
        name: "CASA MARÍA",
        esfera: '/Dreamscapes/Maria/esfera.webp',
        zona: 'Jilotepec Edo. México',
        Reserva: 'https://wa.me',
        textBold: "Casa María fue creada pensando en el descanso adulto. Los ambientes bien decorados, los servicios de spa y una cocina que a fuego lento prepara sus platillos hacen de este espacio un sitio ideal para disfrutar la calma y el silencio.",
        textNormal: 'Casa María es un refugio creado con dedicación y amor, pensado para ofrecer serenidad y belleza a quienes buscan una experiencia única. Este espacio combina confort y privacidad, con cabañas boutique decoradas con detalles únicos de todo el mundo. Aquí, el lujo radica en la autenticidad, con ambientes acogedores, atención personalizada y la paz que brinda la naturaleza circundante, haciendo sentir a cada huésped como en casa.',
        imgPrincipal: '/Dreamscapes/Maria/webbed.webp',
        travesias: 'MÉXICO',
        travesias2: 'KUXTAL',
        imgFondo: "/Dreamscapes/Maria/180A3981.webp",
        galeria: '/Dreamscapes/Maria/WEBGALERY.webp',
        sliderCards: [["CHEF'S TABLE", "SUNBATH PICNIC", "DREAMDATE CINEMA", "HORSEBACK RIDING", "CELESTIAL GETAWAY", "CANDLELIGHT DINNER"]]
    },
    {
        name: "CASA DE WANDA",
        esfera: '/Dreamscapes/Wanda/esfera.webp',
        zona: 'Cancún, Quintana Roo',
        textBold: "Casa de Wanda es una vivencia única que te cautivará desde el primer momento. Construida con amor y dedicación familiar, cada rincón de esta maravillosa casa ha sido diseñado para deleitar tus sentidos y ofrecerte una experiencia inolvidable.",
        textNormal: 'Casa de Wanda te invita a vivir una experiencia única en un ambiente lleno de confort y serenidad. Ubicada en el corazón de Cancún, con vistas al jardín y una piscina privada, cada rincón de la casa refleja un refinamiento que te permite sumergirte en la cultura local. Ofrecemos un bungalow privado y habitaciones diseñadas para tu descanso, con una atención personalizada que asegura una estancia inolvidable, donde cada momento será una experiencia memorable.',
        imgPrincipal: '/Dreamscapes/Wanda/WEBBED.webp',
        travesias: 'MÉXICO',
        travesias2: 'AMATI',
        imgFondo: "/Dreamscapes/Wanda/banner.webp",
        galeria: '/Dreamscapes/Wanda/WEB GALERIA.webp',
        sliderCards: [["UNDERWATER ODYSEEY", "HEAVENLY HEIGHTS", "SUNRISE PADDLES", "MAYAN CLEANSE", "SUNSET YOGA", "AQUA ALFRESCO"]]
    }, {
        name: "Maison Lumi",
        esfera: '/Dreamscapes/Lumi/esfera.webp',
        zona: 'Valle de Bravo Edo. México',
        textBold: "En un entorno único frente al lago, se despiertan sueños que se entrelazan con romance, aventura y amor humano. Bienvenido a un refugio exclusivo y familiar, donde cada momento se transforma en una experiencia mágica. Descubre la esencia de un lugar donde los sueños cobran vida. ¡Bienvenido a un rincón de encanto y felicidad en Maison Lumi!   ",
        textNormal: 'Maison Lumi es un santuario exclusivo frente al lago, donde la belleza natural y la tranquilidad se combinan con comodidad y elegancia. Cada detalle ha sido cuidadosamente diseñado para ofrecerte una experiencia única de privacidad y lujo en un entorno sereno y acogedor, donde la calidad y exclusividad se unen para brindarte momentos excepcionales.',
        imgPrincipal: '/Dreamscapes/Lumi/WEBBED.webp',
        travesias: 'MÉXICO',
        travesias2: 'AMATI',
        travesias3: 'ZAZIL',
        imgFondo: "/Dreamscapes/Lumi/GOPR5402.webp",
        galeria: '/Dreamscapes/Lumi/WEB GALERIA.webp',
        sliderCards: [["CHEF'S TABLE", "SUNBATH PICNIC", "DREAMDATE CINEMA", "HORSEBACK RIDING", "CELESTIAL GETAWAY", "CANDLELIGHT DINNER",]]
    }, {
        name: "Casa Aries",
        esfera: '/Dreamscapes/Aries/esfera.webp',
        zona: 'Costa Careyes, Jalisco',
        textBold: "Casa Aries no es una residencia más, es una oda al arte y su concepto de casa - museo alberga expresión en cada rincón, además de confort y servicios de primera clase. Es propiedad de Fernando Botero Zea, empresario mexicano hijo del legendario artista Fernando Botero.",
        textNormal: 'En una de las costas más impresionantes de Jalisco, México, te invitamos a vivir un sueño lleno de arte, confort y exclusividad. Desde lujosas suites con vistas al mar, cada rincón refleja sofisticación, y cada detalle de tu estancia es personalizado para ser memorable. Con una piscina infinita frente al océano y la opción de disfrutar del vibrante atardecer, este refugio ofrece comodidad de primera clase, transporte al aeropuerto y servicios exclusivos para que cada momento sea una experiencia única e inolvidable.',
        imgPrincipal: '/Dreamscapes/Aries/bed.webp',
        travesias: 'MÉXICO',
        travesias2: 'ZAZIL',
        travesias3: 'AMATI',
        imgFondo: "/Dreamscapes/Aries/principal.webp",
        galeria: '/Dreamscapes/Aries/gallery.webp',
        sliderCards: [["SUNBATH PICNIC", "UNDERWATER ODYSEEY", "HEAVENLY HEIGHTS", "SUNRISE PADDLES", "NAHUATL CLEANSE", "YOGA UNEARTHED",]]
    },
    {
        name: "Hacienda San Antonio Arenillas",
        esfera: '/Dreamscapes/HAA/esfera.webp',
        zona: 'San Francisco Totimehuacán, Puebla',
        textBold: "Hacienda San Antonio Arenillas, ubicada en el corazón de Puebla, es un recinto histórico y hermoso perfecto para todo tipo de eventos, desde bodas y quinceañeras hasta reuniones empresariales y eventos culturales. Con tres hectáreas de jardines impecables, una suite lujosa, y un estacionamiento amplio, esta hacienda ofrece una experiencia única e inigualable, y es reconocida por su gastronomía de clase mundial y servicios de alta calidad.",
        textNormal: 'La Hacienda San Antonio Arenillas, un monumento histórico con una capilla premiada en la Bienal de Arquitectura 2016, destaca por su diseño barroco de los siglos XVII y XVIII. En un entorno de esplendor y elegancia, este lugar en Puebla es perfecto para eventos memorables, combinando la majestuosidad de sus terrenos, la exquisitez de su cocina y el profesionalismo de su equipo para crear experiencias únicas y mágicas.',
        imgPrincipal: '/Dreamscapes/HAA/bed.webp',
        travesias: 'MÉXICO',
        travesias2: 'KUXTAL',
        travesias3: '',
        imgFondo: "/Dreamscapes/HAA/principal.webp",
        galeria: '/Dreamscapes/HAA/gallery.webp',
        sliderCards: [["Bussiness and Executive", "Romance", "Social Getaway",]]
    },
    {
        name: "Carso Retreat",
        esfera: '/Dreamscapes/Carso/esfera.webp',
        zona: 'Miguel Hidalgo, CDMX',
        textBold: "Ubicado en el vibrante corazón de la Ciudad de México, tus sueños cobran vida en medio de la elegancia, la aventura y la genuina calidez. Te damos la bienvenida a Carso Retreat, un enclave exclusivo y acogedor, donde cada momento se convierte en una vivencia mística. Sumérgete en la esencia de un lugar donde los sueños se hacen realidad. ¡Descubre el encanto y el gozo en Carso Retreat!",
        textNormal: 'Carso Retreat, una joya en el corazón de la zona más exclusiva de la Ciudad de México, ofrece una experiencia única de lujo y serenidad. Con una propiedad meticulosamente diseñada y lujosamente amueblada, cada detalle está pensado para brindarte confort, desde sus amplias suites hasta sus espacios comunes. Ya sea para explorar la vibrante vida nocturna, sumergirse en la historia cultural o relajarse en un ambiente tranquilo, Carso Retreat es tu puerta al verdadero lujo y la esencia de la Ciudad de México.',
        imgPrincipal: '/Dreamscapes/Carso/webbed.webp',
        travesias: 'MÉXICO',
        travesias2: 'ZAZIL',
        travesias3: '',
        imgFondo: "/Dreamscapes/Carso/top.webp",
        galeria: '/Dreamscapes/Carso/WEB GALERIA.webp',
        sliderCards: [["SHOPPING HIKE", "YOGA UNEARTHED", "CITY TREKKER", "BIKING BLISS", "NAHUATL CLEANSE", "NATURE'S GAZE ",]]
    }, {
        name: "Boschetto",
        esfera: '/Dreamscapes/Boshetto/esfera.webp',
        zona: 'El Capulín, Cuernavaca',
        textBold: "En Boschetto, un lugar con más de 140 años de historia en un bosque protegido, cada rincón se llena de romance y magia. Nuestro equipo, apasionado por la ecología y la sostenibilidad, se compromete al máximo en la organización de eventos inolvidables. Este lugar se destaca por su elegancia y glamour natural, ofreciendo una experiencia única en la naturaleza. En Boschetto, nada es obligatorio; tú decides qué servicios deseas para tu boda. Con capacidad para hasta 5,000 invitados y una variedad de espacios, te invitamos a descubrir la magia del bosque.",
        textNormal: 'Boschetto es un santuario eco-social en un antiguo bosque protegido, con 140 años de historia, donde elegancia y naturaleza se combinan para crear una experiencia única. Comprometidos con la sostenibilidad y la creatividad, transformamos cada boda en una celebración mágica. Nuestro espacio versátil, que se adapta a cualquier estilo, puede albergar hasta 5,000 invitados, permitiéndote dar vida a tus sueños en un entorno natural. Vive la magia de Boschetto y haz de tu día especial un recuerdo inolvidable.',
        imgPrincipal: '/Dreamscapes/Boshetto/webbed.webp',
        travesias: 'MÉXICO',
        travesias2: 'ZAZIL',
        travesias3: '',
        imgFondo: "/Dreamscapes/Boshetto/top.webp",
        galeria: '/Dreamscapes/Boshetto/WEB GALERIA.webp',
        sliderCards: [["Bussiness and Executive", "Romance", "Social Getaway",]]
    },
    // {
    //     name: "",
    //     esfera: '/Dreamscapes//esfera.webp',
    //     zona: '',
    //     textBold: "",
    //     textNormal: '',
    //     imgPrincipal: '/Dreamscapes//.webp',
    //     travesias: 'MÉXICO',
    //     travesias2: '',
    //     travesias3: '',
    //     imgFondo: "/Dreamscapes//.webp",
    //     galeria: '/Dreamscapes//.webp',
    //     sliderCards: [[""]]
    // },
];

export default Scapes