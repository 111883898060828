import { useLocation, Link, useNavigate } from "react-router-dom";
import Btn from "./Btn";
import Redes from "./Redes";

function Footer() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavigation = (id) => {
        if (location.pathname !== '/idea') {
            navigate('/idea'); // Navega a la página "Idea" si no estás en ella
        }
        setTimeout(() => {
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100); // Da un pequeño tiempo para que la página cargue
    };

    return (
        <footer className={`footer-container ${location.pathname === '/Travesias' ? 'travesias-footer' : ''}`}>
            <div className="footer">
                <section className="contacto">
                    <img src={require('../Img/logo.webp')} alt="logo" />
                    <h2>Contacta a un Experto</h2>
                    <Link to='mailto:dream@dreamgateways.net'>
                        <img src={require('../Img/Icons/iconmensaje.webp')} alt="iconMail" /> dream@dreamgateways.net
                    </Link>
                    <Btn
                        link="https://api.whatsapp.com/send/?phone=5512447233&text=%C2%A1Hola%21+Me+encantar%C3%ADa+que+me+ayudaran+a+agendar+una+traves%C3%ADa+inolvidable&type=phone_number&app_absent=0"
                        clasbtn="chat"
                        target="_blank"
                        text="CHATEAR AHORA"
                    />
                </section>

                <section className="nos-tri">
                    <article className="nosotros">
                        {/* Usando Link con onClick para manejar navegación y desplazamiento */}
                        <Link
                            to="/idea"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavigation('legado');
                            }}
                        >
                            <h2>Idea</h2>
                        </Link>

                        <Link
                            to="/idea"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavigation('dreamers');
                            }}
                        >
                            <h2>Dreamers</h2>
                        </Link>
                    </article>
                    <Redes />
                </section>
            </div>

            <div className="leyenda">
                <p>2023 Dream Gateways</p>
                <p>Políticas de privacidad</p>
            </div>
        </footer>
    );
}

export default Footer;
