import React, { useState } from 'react';
import { ZoomIn, ZoomOut, X } from 'lucide-react';

function ScapeGallery({ galeria }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    // Manejo del arrastre de la imagen
    const handleMouseDown = (e) => {
        if (!isModalOpen) return;
        setIsDragging(true);
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        setPosition({
            x: e.clientX - dragStart.x,
            y: e.clientY - dragStart.y,
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Resetear el zoom y la posición
    const resetZoomAndPosition = () => {
        setScale(1);
        setPosition({ x: 0, y: 0 });
    };

    // Controles de zoom con botones
    const handleZoomIn = () => {
        setScale(Math.min(scale + 0.5, 4));
    };

    const handleZoomOut = () => {
        setScale(Math.max(scale - 0.5, 0.5));
    };

    // Estilos CSS en constantes
    const styles = {
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalImage: {
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transformOrigin: 'center',
            transition: 'transform 0.3s ease-in-out',
        },
        controls: {
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            display: 'flex',
            gap: '0.5rem',
        },
        button: {
            padding: '0.5rem',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            border: 'none',
            borderRadius: '50%',
            transition: 'background-color 0.2s ease',
            cursor: 'pointer',
        },
        icon: {
            width: '1.5rem',
            height: '1.5rem',
            color: 'white',
        },
    };

    return (
        <>
            <section className="galeria flex-center">
                <h1 id="Galeria">Galeria</h1>
                <img
                    src={galeria}
                    alt="galeria"
                    onClick={() => setIsModalOpen(true)}
                />
            </section>

            {isModalOpen && (
                <div
                    style={styles.modal}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                >
                    <img
                        src={galeria}
                        alt="Zoomed galeria"
                        style={styles.modalImage}
                        draggable={false}
                    />
                    <div style={styles.controls}>
                        <button onClick={handleZoomIn} style={styles.button}>
                            <ZoomIn style={styles.icon} />
                        </button>
                        <button onClick={handleZoomOut} style={styles.button}>
                            <ZoomOut style={styles.icon} />
                        </button>
                        <button
                            onClick={() => {
                                setIsModalOpen(false);
                                resetZoomAndPosition();
                            }}
                            style={styles.button}
                        >
                            <X style={styles.icon} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ScapeGallery;
