import React, { useState, useEffect } from 'react';
import { Play, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { createPortal } from 'react-dom';
import Video from '../Home/Video';
import sliderData from './SliderData';
import styles from './styleSlider';

export const VivenciasComponent = ({ slider, names = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const DEFAULT_VIDEO = '/videos/REELDREAMSCAPES.mp4';

    const getSliderData = () => {
        if (names.length > 0) {
            return slider.filter(slide => names.includes(slide.name));
        }
        return slider;
    };

    const filteredSliderData = getSliderData();

    const handleOpenModal = () => {
        setShowModal(true);
        setIsPaused(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsPaused(false);
    };

    const getImageClassName = (slide) => {
        return 'imgSocial';
    };

    // Helper function to determine if we're using the default video
    const isUsingDefaultVideo = () => {
        return !filteredSliderData[currentIndex].urlvideo;
    };

    // Get modal content class names based on video type
    const getModalContentClassName = () => {
        return `modal-content flex-center ${isUsingDefaultVideo() ? 'default-video-modal' : ''}`;
    };

    // Get modal video class names based on video type
    const getModalVideoClassName = () => {
        return `modal-video ${isUsingDefaultVideo() ? 'default-video' : ''}`;
    };

    useEffect(() => {
        let interval;
        if (!isPaused && !showModal) {
            interval = setInterval(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === filteredSliderData.length - 1 ? 0 : prevIndex + 1
                );
            }, 3000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isPaused, showModal, filteredSliderData.length]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === filteredSliderData.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? filteredSliderData.length - 1 : prevIndex - 1
        );
    };

    const additionalStyles = `
        .default-video-modal {
            width: auto !important;
            height: 80% !important;
        }
        
        .default-video {
            width: 300px !important;
        }
    `;

    return (
        <>
            <Video
                classvideo='videoVivencias'
                url='/videos/video.mp4'
            />
            <style>{styles}</style>
            <style>{additionalStyles}</style>
            <div className="slider-container">
                <div
                    className="slider-wrapper"
                    onMouseEnter={() => !showModal && setIsPaused(true)}
                    onMouseLeave={() => !showModal && setIsPaused(false)}
                >
                    <div
                        className="slider-track"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {filteredSliderData.map((slide, index) => (
                            <div
                                key={index}
                                className="slide"
                                style={{ left: `${index * 100}%` }}
                            >
                                <img
                                    className={getImageClassName(slide)}
                                    src={slide.urlimg}
                                    alt={slide.name}
                                />
                                <div
                                    className="slide-overlay"
                                    onClick={handleOpenModal}
                                >
                                    <Play className="play-icon" />
                                </div>
                                <div className="slide-title">
                                    <h3>{slide.name}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className="nav-buttons"
                    onMouseEnter={() => !showModal && setIsPaused(true)}
                    onMouseLeave={() => !showModal && setIsPaused(false)}
                >
                    <button
                        className="nav-button"
                        onClick={handlePrev}
                    >
                        <ChevronLeft />
                    </button>
                    <button
                        className="nav-button"
                        onClick={handleNext}
                    >
                        <ChevronRight />
                    </button>
                </div>
            </div>

            {showModal && createPortal(
                <div className="modal-overlay">
                    <div className={getModalContentClassName()}>
                        <button
                            className="close-button"
                            onClick={handleCloseModal}
                        >
                            <X />
                        </button>
                        <video
                            className={getModalVideoClassName()}
                            src={filteredSliderData[currentIndex].urlvideo || DEFAULT_VIDEO}
                            controls
                            autoPlay
                        >
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

function Vivencias() {
    return (
        <>
            <VivenciasComponent slider={sliderData} />
        </>
    )
}

export default Vivencias;