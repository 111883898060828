import React from 'react'
import { Link } from 'react-router-dom';
// imagenes de redes sociales footer
// Importar las imágenes directamente
import tiktokIcon from '../Img/Icons/tiktokdg.webp';
import facebookIcon from '../Img/Icons/fbdg.webp';
import instagramIcon from '../Img/Icons/instadg.webp';

function Redes() {

    const networks = [
        { name: 'TikTok', url: 'https://www.tiktok.com/@dreamgateways', imgSrc: tiktokIcon },
        { name: 'Facebook', url: 'https://www.facebook.com/dgateways', imgSrc: facebookIcon },
        { name: 'Instagram', url: 'https://www.instagram.com/dreamgateways/', imgSrc: instagramIcon },
    ];

    return (
        <article className="redes">
            {networks.map((red, index) => (
                <Link key={index} to={red.url} target="_blank">
                    <img src={red.imgSrc} alt={red.name} />
                </Link>
            ))}
        </article>
    );
}

export default Redes