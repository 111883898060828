import React from 'react';

function Video({ url, classvideo }) {
    return (
        <section className={`video ${classvideo}`}>
            <video autoPlay loop muted>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </section>
    );
}

export default Video;
