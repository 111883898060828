import React from 'react'
import Video from '../Home/Video'
import Categorias from './Categorias'
import Btn from '../../Components/Btn'

function Travesias() {
    return (
        <div className='travesias'>
            <Video
                classvideo='mrn0'
                url='/videos/Video Viajes.mp4'
            />

            <section className='info-viajes'>
                <p>
                    Descubre <span>Dream Gateways</span>, donde transformamos cada viaje en un capitulo inolvidable.
                </p>
            </section>
            <Categorias
                styleCategory={{ backgroundColor: "#006633" }}
                id='KUXTAL'
                titleCategory='KUXTAL - LifeStream'
                imgcategory='/viajes/KUXTAL.webp'
                textCategory="Inspirado en la palabra náhuatl para 'vida', te sumerges en un santuario de bienestar donde cada instante celebra la esencia de la vida. Fluye con el ritmo natural, desde sesiones de yoga al amanecer hasta rituales de spa ancestrales, y redescubre la conexión con tu ser más profundo, nutriendo cuerpo, mente y espíritu."
            />
            <Categorias
                styleCategory={{ backgroundColor: "#8b0000", textAlign: "right" }}
                id='AMATI'
                titleCategory='AMATI - LoveForAdventure'
                imgcategory='/viajes/AMATI.webp'
                textCategory="Cada experiencia se moldea alrededor de la pasión por descubrir y explorar. Inspirado en la palabra náhuatl 'AMATI', que significa 'me gusta', es el santuario perfecto para los amantes de la aventura, ofreciendo desde caminatas desafiantes hasta inmersiones en ruinas ancestrales. Cada día aquí es una invitación a vivir una aventura inolvidable."
            />
            <Categorias
                styleCategory={{ backgroundColor: "#00008b" }}
                id='ZAZIL'
                titleCategory='ZAZIL - ClearLuxury'
                imgcategory='/viajes/ZAZIL.webp'
                textCategory='En el corazón de la majestuosidad se encuentra ZAZIL - ClearLuxury. Inspirado por la "claridad" maya, ZAZIL es un oasis de refinamiento y serenidad. Aquí, el lujo se manifiesta en amaneceres etéreos y en el sutil placer de un vino al ocaso. Con un servicio que redefine la excelencia, cada detalle en ZAZIL ha sido meticulosamente curado para brindarte una experiencia de lujo supremo.'
            />

            <section className='categoryInfo flex-center'>
                <p>En Dream Gateways, nos esforzamos por superar los límites de la experiencia de viaje. No solo te llevamos a destinos de ensueño, sino que te ayudamos a vivir experiencias que dejan una huella profunda en tu espíritu. A través de nuestras experiencias de bienestar en <span>KUXTAL</span>, nuestras aventuras épicas en <span>AMATI</span> y nuestro lujo sereno y discreto en <span>ZAZIL</span>, cada viaje es una puerta abierta a un nuevo yo.</p>

                <Btn
                    clasbtn='travesiasBtn'
                    link='/vivencias'
                    text='EXPLORAR MÁS'
                />
            </section>
        </div>
    )
}

export default Travesias