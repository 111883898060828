import React from 'react'
import Btn from '../../Components/Btn'

function Explora() {
    return (
        <section className='explora-descrubre'>
            <article className='explora'>
                <h2>Explora nuestras <span>Travesías </span></h2>
                <Btn
                    link='/DreamScapes'
                    text='Explora y Descubre'
                />
                <img src='/ImgIdea/mundo.webp' alt='explorer' />
            </article>

            <article className='descubre'>

                <h2>Diseña un <span>sueño</span></h2>
                <Btn
                    link={`https://api.whatsapp.com/send/?phone=5512447233&text=${encodeURIComponent(
                        `¡Hola! Me encantaría que me ayudaran a agendar una travesía inolvidable`
                    )}`}
                    target='_blank'
                    text='Empezar el sueño'
                />
                <img src="/ImgIdea/sueño.webp" alt="descubre" />
            </article>

        </section>
    )
}

export default Explora