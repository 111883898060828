import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ScapeHeader({ name, imgFondo, travesias, travesias2, travesias3 }) {
    const navigate = useNavigate(); // Hook para manejar la navegación

    const handleNavigation = (id) => {
        // Usar navigate para redirigir a la página deseada
        navigate('/travesias');

        // Esperar un pequeño tiempo para que la página cargue y luego hacer scroll
        setTimeout(() => {
            const section = document.getElementById(id); // Buscar el elemento con el id
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Desplazamiento suave
            }
        }, 100); // Tiempo de espera para asegurar que la página haya cargado
    };

    return (
        <section className="scape-detailHeader flex-center">
            <img src={imgFondo} alt={name} />
            <section className="flex-center">
                <h2>{name}</h2>
                <article>
                    <p>{travesias}</p>
                    <div>
                        {/* Usar Link para navegar y después hacer scroll a la sección correspondiente */}
                        <Link
                            to="/travesias"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavigation(travesias2); // Pasar el id de la sección
                            }}
                        >
                            {travesias2}
                        </Link>
                        <Link
                            to="/travesias"
                            onClick={(e) => {
                                e.preventDefault();
                                handleNavigation(travesias3); // Pasar el id de la sección
                            }}
                        >
                            {travesias3}
                        </Link>
                    </div>
                </article>
            </section>
        </section>
    );
}

export default ScapeHeader;
