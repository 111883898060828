import React from 'react';
import { useNavigate } from 'react-router-dom';
import Scapes from './ScapeDetails';

function CardsPost() {
    const navigate = useNavigate();

    const handleClick = (name) => {
        // Convertir el nombre a un formato URL amigable (slug)
        const slug = name.toLowerCase().replace(/\s+/g, '-');
        navigate(`/scape/${slug}`);
    };

    return (
        <>
            {Scapes.map((scape, index) => (
                <section
                    key={index}
                    className='scape flex-center'
                    onClick={() => handleClick(scape.name)}
                    style={{ cursor: 'pointer' }}
                >
                    <img
                        src={scape.esfera}
                        alt={scape.name}
                    />

                    <article className='flex-center'>
                        <p>{scape.zona}</p>
                        <h2>{scape.name}</h2>

                        <aside>
                            <span>{scape.travesias}</span>
                            <div>
                                <span>{scape.travesias2}</span>
                                {/* Mostrar travesias3 solo si no está vacío */}
                                {scape.travesias3 && <span>{`-${scape.travesias3}`}</span>}
                            </div>
                        </aside>
                    </article>
                </section>
            ))}
        </>
    );
}

export default CardsPost;
