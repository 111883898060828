import React from 'react';
import { Link } from 'react-router-dom';

function Btn({ link, clasbtn, target, text }) {
    return (
        <Link
            to={link}
            className={`button ${clasbtn}`}

            target={target}>
            {text}
        </Link>
    );
}

export default Btn;
