// ScapeContent.js
import React from 'react';

function ScapeContent({ imgPrincipal, textBold, textNormal }) {
    return (
        <section className="Container" id="Info">
            <img src={imgPrincipal} alt="Bed" />
            <article >
                <span>{textBold}</span>
                <br />
                <hr />
                <p>{textNormal}</p>
            </article>
        </section>
    );
}

export default ScapeContent;
