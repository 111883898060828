import React from 'react'
import Btn from '../../Components/Btn';

function ContainService() {
    return (
        <section className='contain-service'>

            <div className='service'>
                <Service
                    url='https://dreamgateways.net/DreamData/Icons/lib.webp'
                    title='Vivencias'
                    description='Te ayudamos a encontrar las mejores vivencias acorde a lo que sueñas para esa travesía única.'
                />

                <Service
                    clas='LogoService'
                    url='https://dreamgateways.net/DreamData/logo.webp'

                    description='Nos esforzamos por brindar el mejor servicio y atención personalizada, adaptándonos a las necesidades específicas de nuestros clientes para asegurarnos de que tengan una experiencia memorable e inolvidable en su travesía.'
                />

                <Service
                    url='https://dreamgateways.net/DreamData/Icons/per.webp'
                    title='Social+'
                    description='¿Buscas un lugar encantador para celebrar o realizar algún evento especial?'
                />
            </div>

            <Btn
                link='/Travesias'
                text='MÁs información'
            />
        </section>
    );
}

function Service({ clas, url, title, description }) {
    return (
        <>
            <article>
                <img className={clas || ''} src={url} alt='serviceImg' />
                {title && <h3>{title}</h3>}
                <p>{description}</p>
            </article>
        </>
    )
}

export default ContainService