// Scape.js
import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Scapes from './ScapeDetails';
import ScapeHeader from './ScapeHeader';
import ScapeMenu from './ScapeMenu';
import ScapeContent from './ScapeContent';
import ScapeVivencias from './ScapeVivencias';
import ScapeGallery from './ScapeGallery';
import sliderData from '../Vivencias/SliderData';

function Scape() {
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();

    const scape = Scapes.find(s =>
        s.name.toLowerCase().replace(/\s+/g, '-') === slug
    );

    if (!scape) {
        return <div>No se encontró el Scape</div>;
    }

    const handleNavigation = (id) => {
        if (location.pathname !== '/travesias') {
            navigate('/travesias');
        }
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'END' });
        }
    };

    return (
        <div className="scape-detail flex-center">
            <ScapeHeader
                name={scape.name}
                imgFondo={scape.imgFondo}
                travesias={scape.travesias}
                travesias2={scape.travesias2}
                travesias3={scape.travesias3}
                handleNavigation={handleNavigation}
            />
            <ScapeMenu scapeName={scape.name} />
            <ScapeContent
                imgPrincipal={scape.imgPrincipal}
                textBold={scape.textBold}
                textNormal={scape.textNormal}
            />
            <ScapeVivencias sliderCards={scape.sliderCards} sliderData={sliderData} />
            <ScapeGallery galeria={scape.galeria} />
        </div>
    );
}

export const Data = [
    {
        name: "BEACH&SAND GETAWAY",
        urlimg: "/vivencias_imagen/2.webp",
        urlvideo: "/videos/VIVENCIAS/2.mp4"
    },
];

export default Scape;
