import React from 'react'
import Video from '../Home/Video'
import Img from '../../Components/Img'
import Categorias from '../Trevesias/Categorias'
import Explora from './Explora'

function Idea() {
    return (
        <div className='idea'>
            <Video
                classvideo='mrn0'
                url='/videos/idea.mp4'
            />

            <section className='legado' id="legado">
                <h2>Nuestro Legado</h2>

                <p><span>Con la pasión por la hospitalidad como nuestra guía, Dream Gateways nace para transformar sueños en experiencias tangibles y memorables.
                </span>
                    <br />
                    <br />
                    Sumergidos en la innovación y comprometidos con la sociedad y el medio ambiente, nos esforzamos por realzar la belleza de destinos increíbles y forjar conexiones que perduran en el tiempo. Descubrimos los secretos ocultos de la jungla urbana para ofrecerte refugios únicos y experiencias sin igual. En Dream Gateways, cada latido de nuestro corazón está dedicado a convertir cada momento en un recuerdo inolvidable.
                </p>

                <Img
                    img='/ImgIdea/wow!'
                    alt='Legos'
                />
            </section>

            <section className='img3Idea'>
                <Img
                    img='/ImgIdea/soulEs'
                    alt='SoulEs'
                />

                <Img
                    img='/ImgIdea/misionEs'
                    alt='misionEs'
                />

                <Img
                    img='/ImgIdea/visionEs'
                    alt='visionEs'
                />
            </section>

            <Categorias
                id="dreamers"
                styleCategory={{ color: 'var(--color-fondo)', fontWeight: 'bold' }}
                titleCategory='Conoce a Nuestros Dreamers'
                classImg='dreamers'
                imgcategory='/ImgIdea/DREAMERS.webp'
                textCategory={
                    <>
                        <span>
                            En Dream Gateways, la excelencia es más que un estándar, es una forma de vida. Nuestro equipo es un crisol de mentes creativas, innovadoras y realistas que se esfuerzan por superar las expectativas en cada detalle.
                        </span>
                        <br />
                        <br />
                        Nos enorgullece diseñar experiencias personalizadas que son tanto memorables como transformadoras, siempre con un profundo respeto por nuestras comunidades locales y el medio ambiente. Tu sueño es nuestra inspiración y tu satisfacción, nuestra mayor recompensa.
                    </>
                }
            />

            <Explora />
        </div>
    )
}

export default Idea