import { React, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import Home from './Es/Home/Home';
import Travesias from './Es/Trevesias/Travesias';
import DreamScapes from './Es/DreamScapes/DreamScapes';
import Vivencias from './Es/Vivencias/Vivencias';
import Social from './Es/Social/Social';
import Footer from './Components/Footer';
import Idea from './Es/Idea/Idea';
import Scape from './Es/DreamScapes/Scape';

function HeaderContent() {
  const domainMapping = {
    en: 'https://dream.dreamgateways.net',
    es: 'http://dreamgateways.net',
  };

  const [menuActive, setMenuActive] = useState(false);
  const [languageMenuActive, setLanguageMenuActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMenuActive(false);
    setLanguageMenuActive(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuActive(!languageMenuActive);
  };

  const handleLinkClick = () => {
    setMenuActive(false);
    setLanguageMenuActive(false);
  };

  const handleLanguageChange = (lang) => {
    const currentPath = location.pathname; // Mantener la ruta actual
    const newDomain = domainMapping[lang]; // Obtener el dominio según el idioma
    if (newDomain) {
      window.location.href = `${newDomain}${currentPath}`; // Redirigir al dominio con la misma ruta
    } else {
      console.error('El idioma seleccionado no tiene un dominio configurado.');
    }
  };

  const scrollToTribe = (e) => {
    e.preventDefault();
    setMenuActive(false);
    const tribeSection = document.querySelector('.nos-tri');
    if (tribeSection) {
      tribeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header>
      <Link to="/" onClick={handleLinkClick}>
        <img className="logo" src={require('./Img/logo.webp')} alt="logo" />
      </Link>

      <nav className={menuActive ? 'active' : ''}>
        <Link to="/Travesias" onClick={handleLinkClick}>Travesías</Link>
        <Link to="/DreamScapes" onClick={handleLinkClick}>Dreamscapes</Link>
        <Link to="/Vivencias" onClick={handleLinkClick}>Vivencias</Link>
        <Link to="/Social" onClick={handleLinkClick}>Social+</Link>
        <a href="#tribe" onClick={scrollToTribe}>Tribe</a>
        {/* Language Dropdown */}
        <div className="languageDropdown">
          <button onClick={toggleLanguageMenu}>Language</button>
          {languageMenuActive && (
            <div className="languageOptions flex-center">
              {/* Botón para Español, redirige al dominio correspondiente */}
              <button onClick={() => handleLanguageChange('es')}>Español</button>
              {/* Botón para Inglés, redirige al dominio correspondiente */}
              <button onClick={() => handleLanguageChange('en')}>English</button>
            </div>
          )}
        </div>
      </nav>

      <div className="menuIcon" onClick={toggleMenu}>
        <img className="icon" src={require('./Img/Icons/menu.webp')} alt="iconMenu" />
        <p className="menuText">MENU</p>
      </div>
    </header>
  );
}

function Header() {
  return <HeaderContent />;
}

function App() {
  return (
    <BrowserRouter
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }} // Activando los flags futuros
    >
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Travesias" element={<Travesias />} />
          <Route path="/DreamScapes" element={<DreamScapes />} />
          <Route path="/Scape/:slug" element={<Scape />} />
          <Route path="/Vivencias" element={<Vivencias />} />
          <Route path="/Social" element={<Social />} />
          <Route path="/Idea" element={<Idea />} />
          <Route path="/Tribe" />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
